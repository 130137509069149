import React, { useEffect, useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SalesSeaction from './SalesSeaction';
import CustomeNabar from '../UiComponents/CustomNavbar';
import { IoArrowBack } from "react-icons/io5";
import Tender from './Tender';
import HourSale from './HourSale';
import Dailyops from './Dailyops';
import { Link } from 'react-router-dom';
import CustomerSale from './CustomerSale';
import SalesMan from './SalesMan';
import DataService from '../services/requestApi'
import MageTab from './Mangement/MageTab';
import Invatory from './Invantory/Invatory';
import { useDispatch } from 'react-redux';
import { handleInventory, handleLowStock, handleSalesOverview, handleTotalNumberOfiteam, handletotalcustomer } from '../Redux/Reducer';
import TaxGetHsn from './HSNMaster';
import HSNMaster from './HSNMaster';
import GSTR3Report from './GSTR3Report';
import Reports from './Reports';
import AllDailyLog from './DailyLogs/AllDailyLog';
import ReconcilReport from './StoreinsightsModals/ReconcilReport';
import TabForTill from './TillAndReg/TabForTill';

const DashBordMain = () => {
  const dispatch = useDispatch()
  const [currentPage, setCurrentPage]= useState('1')
  const [Activetab, setActivetab] = useState('')
  const [count, setCount] = useState('')
 //  concept all
const [concept, setConcept]= useState('')
const GetConcept=async()=>{
  try {
    const response =await DataService.GetConceptAll()
    console.log("Get Average Sales", response.data.data)
    setConcept(response.data.data)


  } catch (error) {
    console.log("error", error)
  }

}

//Hsn Mster Tab Api Hit
const [Hsnmaster, setHsnmaster] = useState("")
  const GetHsnMaster=async()=>{
  try {
    const response = await DataService.GetAllHsnMaster()
    setHsnmaster(response.data.tax_item_list)
    console.log("this dept", response.data)
  } catch (error) {
    console.log("this is catch ",error)
  }
  }
// customer master
const [customer, setCustomer]= useState('')
const Getcustomer=async()=>{
  try {
    const response =await DataService.GetAllCustomer(currentPage)
    console.log("Get Average Sales", response.data.data)
    setCustomer(response.data.data)
    setCount(response.data.count)
  } catch (error) {
    console.log("error", error)
  }

}

// Daily Logs


  //Sales Man Api call
  const [Salesmandata, setSalesman] = useState()
  const SalesManData=async ()=>{
    try {
      const response = await DataService.GetAllSalesMan(currentPage)
      console.log('this is sales man',response.data.data)
      setSalesman(response.data.data)
       setCount(response.data.count)
      
    } catch (error) {
      console.log('this is catch',error)
    }
  }
  // Search api call by key word and set data in setSalesman 
  const [searchKey, setSearchKey] = useState("")
  const [debounceTimer, setDebounceTimer] = useState(null);

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer); // Clear any existing timer
    }

    if (searchKey !== "") {
      // Set a debounce timer of 500ms
      const timer = setTimeout(() => {
        GetSalesmanBySearch(searchKey);
      }, 500);
      setDebounceTimer(timer);
    } else {
      SalesManData(); // Call the function to fetch all salesmen if no search key is provided
    }

    // Cleanup function to clear the timer if the component is unmounted
    return () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [searchKey]);
  const GetSalesmanBySearch = async (searchKey) => {
    try {
      const response = await DataService.GetSalesManBySearch(searchKey)
      console.log('this is search man',response.data.data)
      setSalesman(response.data.data)
       setCount(response.data.count)
    } catch (error) {
      console.log('this is catch',error)
    }
  }


  //Handel Pagination
const handlePageChange = (page) => {
  console.log("this pagination page",page)
  setCurrentPage(page);
};

useEffect(() => {
if(Activetab=='Sales Man'){
  SalesManData()
}else if(Activetab=='Manage'){
  GetConcept()
}else if(Activetab=='customer'){
  Getcustomer()
}else if(Activetab=='GSTR1 Report'){
  GetHsnMaster()
}

}, [Activetab,currentPage])


// <---------------------Invantory Api's call ---------------->
const { saasId, storeId ,userType} = JSON.parse(localStorage.getItem("User_data"))
const GetSalesOverview=async()=>{
try {
  const response = await DataService.SalesOverView(saasId,storeId)
  // console.log(response.data)
  dispatch(handleSalesOverview(response.data.data))
} catch (error) {
  console.log(error)
}
}


const InventorySummery=async()=>{
try {
  const response = await DataService.InventorySummary(saasId,storeId)
  // console.log(response.data)
  dispatch(handleInventory(response.data.data))
} catch (error) {
  console.log(error)
}
}

const TotalCustomer = async ()=>{
  try {
    const response = await DataService.TotalCustomer(saasId,storeId)
    console.log(response.data.data)
    dispatch(handletotalcustomer(response.data.data))
  } catch (error) {
     console.log(error)
  }
}


const ClosingQty=async ()=>{
  try {
   const response =await DataService.ClosingQty(saasId,storeId)
   console.log(response.data)
   dispatch(handleLowStock(response.data.data))
  } catch (error) {
    console.log(error)
  }
}

const TotalnoofItem=async ()=>{
  try {
    const response = await DataService.TotalNoofItem(saasId,storeId)
    console.log(response.data)
    dispatch(handleTotalNumberOfiteam(response.data.data))
  } catch (error) {
    console.log(error)
  }
}

  return (
    <>
    <div className='d-flex justify-content-between'>
     <Link to={'/'}> <IoArrowBack className='mt-2 fs-3'/> </Link> 
    <CustomeNabar/>
    <div>
      
    </div>
    </div>
    <Tabs
      defaultActiveKey="Store Insights"
      id="uncontrolled-tab-example"
      className="mb-3  fw-bold"
      fill
      style={{background: '#FFF',
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
        }}
        onSelect={(key)=>{
          setActivetab(key)
        }}
        
    >
      <Tab eventKey="Store Insights" title="Store Insights">
      <Tabs
      defaultActiveKey="sales"
      id="uncontrolled-tab-example"
      className="mb-3  fw-bold"
      fill
      style={{background: '#FFF',
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
        }}
        onSelect={(key)=>{
          console.log("Active tab",key)
          setActivetab(key)
            if(key=="Invantory"){
              GetSalesOverview()
              InventorySummery()
              TotalCustomer()
              ClosingQty()
              TotalnoofItem()
            }
        }}
    
    >
      <Tab eventKey="sales" title="Sales">
      <SalesSeaction  src={`https://s3-alpha-sig.figma.com/img/5c7b/26a9/2a0f6a0501bcb8b8809cb6689e06aa7c?Expires=1702252800&Signature=JO4W1NNoj~M6oV2TtICaznJ0CP60u3ZwN4y2U4JX3E2vccW3G4ir71a0mve91ibacKSztiGnLDQI5Arw9tKtcFwZ~QE6eb4GOtrP3HDNBAfy50JoVdBsYKvbOn4QUI~a8isw11QQ-MIHPT~EUvC0VRMtpjSF2n1dPThvbluGQE9ZcFwIKydUqebScSDiRoz9AH3Bzwgiq4rzjjFjjdbAJUPGRontLEbE~~ncdj12PAkdCCFy2xhp7r4FfFPzzEw0QkvmUktSRumDDPq7fIdFvmYStO2hRck3qqpeuw766ZVAOQyIdbZwRDnfcqAMM31iX9ywZ8xjPUsYQiCrFREmvw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4`}/>
      </Tab>
      <Tab eventKey="tender" title="Tender">
        <Tender />
      </Tab>
      <Tab eventKey="hourly" title="Hourly Sale">
        <HourSale/>
      </Tab>
      {/* <Tab eventKey="Daily" title="Daily Ops">
        <Dailyops tab={Activetab}/>
      </Tab> */}
      <Tab eventKey="Reports" title="Reports">
        <Reports/>
      </Tab>
      <Tab eventKey="Invantory" title="Inventory">
        {/* <Dailyops/> */}
        <Invatory />
      </Tab>
      {userType.toUpperCase()=="ALL" &&<Tab eventKey="ReconReport" title="Reconcil Report">
        <ReconcilReport tab={Activetab} />
      </Tab>}
    
    </Tabs>
      </Tab>
      { userType.toUpperCase()=="ALL" &&<Tab eventKey="customer" title="Customer">
        <CustomerSale Getcustomer={Getcustomer} setCustomer={setCustomer} count={count} customer={customer} handlePageChange={handlePageChange}/>
      </Tab>}
      { userType.toUpperCase()=="ALL" &&<Tab eventKey="DailyLogs" title="DailyLogs">
        <AllDailyLog tab={Activetab}/>
      </Tab>}
      { userType.toUpperCase()=="ALL" &&<Tab eventKey="GSTR1 Report" title="GSTR1 Report">
        <HSNMaster GetHsnMaster={GetHsnMaster} Hsnmaster={Hsnmaster}/>
      </Tab>}
      { userType.toUpperCase()=="ALL" &&<Tab eventKey="GSTR3 Report" title="GSTR3 Report">
        <GSTR3Report/>
      </Tab>}
      {/* { userType.toUpperCase()=="ALL" &&<Tab eventKey="Till&REG" title="Till & Reg">
        <TabForTill/>
      </Tab>} */}
      { userType.toUpperCase()=="ALL" &&<Tab eventKey="Sales Man" title="Sales Man">
        <SalesMan setSearchKey={setSearchKey} count={count} handlePageChange={handlePageChange} data={Salesmandata} SalesManData={SalesManData}/>
      </Tab>}
      { userType.toUpperCase()=="ALL" && <Tab eventKey="Manage" title="Manage">
       <MageTab concept={concept} GetConcept={GetConcept}/>
      </Tab>}
    </Tabs>
  
    </>
  )
}

export default DashBordMain