import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { useEffect } from 'react';
import DataService from '../../services/requestApi'
const AllREGOpend = () => {
    const [data, setData] = useState([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await DataService.GetAllOpenREG();
                setData(response.data.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    const columns = [
        {
            name: 'Register',
            selector: row => row.register,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
        },
        {
            name: 'Opened At',
            selector: row => row.openedAt,
            sortable: true,
        },
    ];
    return (
        <div>
            <h1>All REG</h1>
            <DataTable
                columns={columns}
                data={data}
                pagination
            />
        </div>
    );
};

export default AllREGOpend;