import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import DataService from '../../services/requestApi'
const GetAllTill = () => {
    const [tills, setTills] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const fetchTills = async () => {
        try {
            const response = await DataService.GetAllOpenTill();
            setTills(response.data.data);
        } catch (error) {
            console.error('Error fetching tills:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTills();
    }, []);
     

    const handleCloseTill = async (saasid, storeId, Tillid)=>{
        try {
            const Res = await DataService.TillRegisterClose(saasid, storeId, Tillid)
        } catch (error) {
            console.log(error)
        }
    }

    const columns = [
        {
            name: 'Till ID',
            selector: row => row.tillId,
            sortable: true,
        },
        {
            name: 'Cashier Id',
            selector: row => row.cashierId,
            sortable: true,
        },
        {
            name: 'Open Time',
            selector: row => row.openTime,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.tillStatus,
            sortable: true,
        },
        {
            name: 'Flat Amount',
            selector: row => row.floatAmount,
            sortable: true,
        },
        {
            name: 'Action',
            cell: row => (
                <button
                    onClick={() => handleCloseTill(row.saasId, row.storeId,row.tillId)}
                    disabled={row.tillStatus === 'Closed'}
                >
                    Close
                </button>
            ),
        }
    ];

    return (
        <div>
            <h1>All Tills</h1>
            <DataTable
                columns={columns}
                data={tills}
                progressPending={loading}
                pagination
            />
        </div>
    );
};

export default GetAllTill;