import React, { useState } from "react";

import { Form } from "react-bootstrap";
import DataService from "../../services/requestApi";
import { BASEURL } from "../../services/http-common";
import axios from "axios";
import Swal from "sweetalert2";
import CreateCoupon from "./CreateCoupon";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CouponPermotionRule from "./CouponPermotionRule";
import DisablePermotion from "./DisablePermotion";
import FlatDiscountForm from "./MangeAllModal/FlatDiscountForm";
import GetAlFlatDiscount from "./GetAlFlatDiscount";
const CouponMaster = () => {
  const { saasId } = JSON.parse(localStorage.getItem("User_data"));
  const [Data, setData] = useState({
    coupon_name: "",
    store_id: "",
    saas_id: saasId,
    effective_from: "",
    expiration_date: "",
    description: "",
    active: true,
    discount_type: "",
    discount_amount: "",
    discount_percent: "",
    max_discount: 100.0,
    min_purchased_amount: "",
    loyalty_id: "",
  });
  const [mobail, setMobail] = useState("");
  const handleonchange = (e) => {
    console.log(e.target.name, e.target.value);
    setData({ ...Data, [e.target.name]: e.target.value });
  };

  const GetLoyaltyCusomer = async () => {
    try {
      const data = {
        mobile_number: mobail,
        base_currency: "INR",
        client_id: "8",
      };
      const response = await DataService.GetLoyaltyCustomer(data);
      if (response.status == 200) {
        //  setData({...Data,["loyalty_id"]:response.data.loyalty_id})
        CreateCoupn(response.data.loyalty_id);
      }
      console.log(response.data);
    } catch (error) {
      console.log(error);
      Swal.fire({ title: error?.response?.data?.error_message, timer: 2000 });
    }
  };

  const CreateCoupn = async (id) => {
    try {
      const CoupunData = {
        coupon_name: Data.coupon_name,
        store_id: Data.store_id,
        saas_id: saasId,
        effective_from: Data.effective_from,
        expiration_date: Data.expiration_date,
        description: Data.description,
        active: true,
        discount_type: Data.discount_type,
        discount_amount: Data.discount_amount,
        discount_percent: Data.discount_percent,
        max_discount: 100.0,
        min_purchased_amount: Data.min_purchased_amount,
        loyalty_id: id,
      };
      const Response = await DataService.CreatCoupun(CoupunData);
      if (Response.data.status) {
        Swal.fire({
          title: "Coupon Created Successfully",
          icon: "success",
          timer: 2000,
        });
        setMobail('')
        setData({
          coupon_name: "",
          store_id: Data.store_id,
          saas_id: saasId,
          effective_from: "",
          expiration_date: "",
          description: "",
          active: true,
          discount_type: "per",
          discount_amount: "",
          discount_percent: "",
          max_discount: 100.0,
          min_purchased_amount: "",
          loyalty_id: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} variant="fullWidth" aria-label="lab API tabs example">
            <Tab label="Create Coupon" value="1" />
            <Tab label="Permotion Rule For Coupon" value="2" />
            <Tab label="Disable All Permotion" value="3" />
            <Tab label="Apply Flat Discount" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1">
      <CreateCoupon GetLoyaltyCusomer={GetLoyaltyCusomer} mobail={mobail} Data={Data} setMobail={setMobail}  handleonchange={handleonchange}/>
          </TabPanel>
        <TabPanel value="2">
          <CouponPermotionRule/>

        </TabPanel>
        <TabPanel value="3">
        <DisablePermotion/>
        </TabPanel>
        <TabPanel value="4">
          <GetAlFlatDiscount/>
         {/* <FlatDiscountForm value={value}/> */}
        </TabPanel>
        
      </TabContext>
    </Box>
    </>
  );
};

export default CouponMaster;
