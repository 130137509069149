import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import DataService from '../../services/requestApi'
import { Container } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Modal, Box, Typography, TextField } from '@mui/material';
import FlatDiscountForm from './MangeAllModal/FlatDiscountForm';
import Swal from 'sweetalert2';
const GetAlFlatDiscount = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [data, setData] = useState([])

    const  GetData = async ()=>{
        try {
            const res = await  DataService.GetAllFlatDiscount()
            setData(res.data.data)
            
        } catch (error) {
            console.log(error)
        }
    }
    const DeleteFalt = async (id)=>{
        try {
            const res =  await DataService.DeleteFlatDiscount(id)
            if(res.data.status){
                Swal.fire({
                    title:"Deleted SuccessFully",
                    icon:'success',
                    timer:2000
                })
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        GetData()
    }, [])
    
    const columns = [
        {
            name: 'Store ID',
            selector: row => row.storeId,
            sortable: true,
        },
        {
            name: 'Discount',
            selector: row => row.discount,
            sortable: true,
        },
        {
            name: 'Start Date',
            selector: row => row.startdate,
            sortable: true,
        },
        {
            name: 'End Date',
            selector: row => row.enddate,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => (
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() =>handleOpen()}
                >
                    Edit
                </Button>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    {
        name: 'Delete',
        cell: row => (
        <Button
            variant="contained"
            color="error"
            onClick={() => DeleteFalt(row.storeId)}
        >
            Delete
        </Button>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
    }
    ];
  return (
    <Container>
        <div className="d-flex justify-content-between align-items-center px-3">
        <h1 className="fs-3">Create Flat Discount</h1>
        <div className="d-flex gap-3">
          <Button
            className="fw-bold text-white"
            style={{ background: "rgb(14, 41, 84)" }}
            variant="contained"
            color="primary"
            onClick={() =>handleOpen()}
          >
            Create Flat Discount
          </Button>
        </div>
      </div>
      {/* <AddConcept open={OpenAddCon} GetConcept={GetConcept} setOpenAddCon={setOpenAddCon}/> */}
      {/* <UpdateConeptModal open={OpenUpdate} GetConcept={GetConcept} setOpenUpdate={setOpenUpdate} row ={SelectedRow}/> */}
    <DataTable
        columns={columns}
        responsive={true}
        fixedHeader={true}
        // fixedHeaderScrollHeight="300px"

        data={data}
        // progressPending={loading}
        pagination
        paginationServer
        // paginationTotalRows={item_master_list ? item_master_list.totalCount : 1}
        // onChangeRowsPerPage={10}
        // onChangePage={handlePageChange}
      />
      <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 500, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                 <FlatDiscountForm GetData={GetData} value= "4" handleClose={handleClose}/>
                    {/* <Typography id="modal-title" variant="h6" component="h2">
                        Create Flat Discount
                    </Typography>
                    <TextField fullWidth margin="normal" label="Store ID" variant="outlined" />
                    <TextField fullWidth margin="normal" label="Discount" variant="outlined" />
                    <TextField fullWidth margin="normal" label="Start Date" variant="outlined" />
                    <TextField fullWidth margin="normal" label="End Date" variant="outlined" />
                    <Button variant="contained" color="primary" onClick={handleClose} style={{ marginTop: '16px' }}>
                        Save
                    </Button> */}
                </Box>
            </Modal>
      
  </Container>
  )
}

export default GetAlFlatDiscount
