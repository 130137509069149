import React from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import GetAllTill from './GetAllTill'
import AllREGOpend from './AllREGOpend'

const TabForTill = () => {
  return (
    <div>
        <Tabs
      defaultActiveKey="Till"
      id="uncontrolled-tab-example"
      className="mb-2  fw-bold"
      fill
      style={{background: '#FFF',
        boxShadow: "4px 4px 10px 0px rgba(0, 0, 0, 0.25)"
        }}
        onSelect={(key)=>{
        }}
        
    >
        <Tab eventKey="Till" title="All Tills">
               <GetAllTill/>
        </Tab>
        <Tab eventKey="REG" title="REGISTER">
               <AllREGOpend/>
        </Tab>

    </Tabs>
    </div>
  )
}

export default TabForTill